<template>
  <b-card>
    <div v-show="!loading.first">
      <table-render :schema="schema" :rows="dataTable" :fixed="true">
        <template #prioritySlot="scope">
          <feather-icon icon="CircleIcon" 
            :style="[{'color': `${scope.rowdata.priority_color ? scope.rowdata.priority_color : 'red'}`, fill: `${scope.rowdata.priority_color ? scope.rowdata.priority_color : 'red'}`}] " />
          {{scope.rowdata.priority_label}}
        </template>
        <template #statusSlot="scope">
          <feather-icon icon="CircleIcon" 
            :style="[{'color': `${scope.rowdata.current_delivery_status_color ? scope.rowdata.current_delivery_status_color : 'red'}`, fill: `${scope.rowdata.current_delivery_status_color ? scope.rowdata.current_delivery_status_color : 'red'}`}] " />
          {{scope.rowdata.current_delivery_status_name}}
        </template>
        <template #managementSlot="scope">
          {{formatDate(scope.rowdata.updated_at)}}
        </template>
        <template #currentTypeSlot="scope">
          {{ scope.rowdata.current_type_name }}
          <b-button @click="openModalUpdate(scope.rowdata)" ref="" class="p-0" variant="link"><feather-icon  icon="Edit2Icon" class="mr-50"/></b-button>
        </template>
      </table-render>
      <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
    </div>
    <div v-show="loading.first">
      <b-skeleton-table
        :rows="pagination.limit || 10"
          :columns="6"/>
    </div>
  </b-card>
</template>
<script>
export default {
  props: ['dataTable', 'loading', 'pagination', 'source'],
  data() {
    return {
      schema: [],
      rows: [],
      detailInstruction: null
    }
  },
  watch: {
    'pagination.page'(value) {
      this.$emit('changePagination', value)
    },
    'pagination.limit'(value) {
      this.$emit('changeLimit', value)
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.schema = [
        {label: 'Instrucción gestionada', key: 'current_type_name', sortable: true},
        {label: 'Respuesta', key: 'comment', sortable: true},
        {label: 'Fecha gestión', key: 'managementSlot', useSlot: true},
        {label: 'OT', key: 'delivery_tracking_number', sortable: true},
        {label: 'Estado', key: 'statusSlot', useSlot: true},
        {label: 'Seller', key: 'company_name', sortable: true},
        {label: 'Domicilio', key: 'full_address', sortable: true}
        // {label: 'Acciones', key: 'actionsSlot',  useSlot: true, class: ['text-center thead--light']}
      ]
    },
    formatDate(date) {
      return date ? this.$options.filters.dbDateToFormat(date, 'dd-LL-y T') : ''
    },
    openModalUpdate(data) {
      this.detailInstruction = data
      this.$bvModal.show('idUpdateRequest')
    },
    closeModalUpdate() {
      this.detailInstruction = null
      this.$bvModal.hide('idUpdateRequest')
    }
  }
}
</script>