<template>
  <b-card>
    <b-container>
      <b-row>
        <b-col>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group :aria-describedby="ariaDescribedby">
              <b-form-radio v-model="selected" value="pending">Pendientes</b-form-radio>
              <b-form-radio v-model="selected" value="resolved">Atendidas</b-form-radio>
              <b-form-radio v-model="selected" value="desactivated">Deshabilitadas</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-button @click="downloadPending()" class="p-1" variant="outline-success"><feather-icon  icon="DownloadIcon" class="mr-50"/> Descargar pendientes</b-button>
            </b-col>
            <b-col>
              <b-row class="justify-content-end"><b-button v-b-modal.idUploadAnswersRequest class="p-1" variant="outline-success"><feather-icon  icon="UploadIcon" class="mr-50"/> Importar respuestas</b-button></b-row>
              <b-row class="justify-content-end"><a href="https://storage.googleapis.com/tickets-misc/importacion_carga_masiva_carriers.xlsx" class="text-success">Plantilla ejemplo</a></b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <table-instructions
      v-show="selected === 'pending'"
      :loading="loading"
      :dataTable="dataComponents.listInstructions"
      :pagination="pagination"
      :arrayAnswerCarrier="arrayAnswerCarrier"
      @changePagination="changePagination($event)"
      @changeLimit="changeLimit($event)"
      @uploadSuccess="changePagination(1)"></table-instructions>
    <table-instructions-resolved
      v-show="selected === 'resolved'"
      :loading="loading"
      :dataTable="dataComponents.listInstructions"
      :pagination="pagination"
      @changePagination="changePagination($event)"
      @changeLimit="changeLimit($event)"></table-instructions-resolved>
    <table-instructions-resolved
      v-show="selected === 'desactivated'"
      :loading="loading"
      :dataTable="dataComponents.listInstructions"
      :pagination="pagination"
      @changePagination="changePagination($event)"
      @changeLimit="changeLimit($event)"></table-instructions-resolved>
    <upload-answer @uploadSuccess="changePagination(1)"></upload-answer>
  </b-card>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol
} from 'bootstrap-vue'
import TableInstructions from './components/TableInstructions.vue'
import TableInstructionsResolved from './components/TableInstructionsResolved.vue'
import UploadAnswer from './components/UploadAnswer.vue'
import BaseServices from '@/store/services/index'

export default {
  data() {
    return {
      baseService: new BaseServices(this),
      dataComponents: {
        listInstructions: []
      },
      loading: {
        first: false
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        noShowDigits: true
      },
      selected: '',
      arrayAnswerCarrier: [],
      carrierCode: ''
    }
  },
  components: {
    TableInstructions,
    TableInstructionsResolved,
    UploadAnswer,
    BContainer,
    BRow,
    BCol
  },
  watch: {
    'selected': {
      handler(value) {
        this.changePagination(1)
      },
      deep: true
    }
  },
  mounted() {
    this.getAnswerCarrier()
    this.selected = 'pending'
  },
  methods: {
    changePagination(value) {
      this.pagination.page = value
      this.getInstructionData()
    },
    changeLimit(value) {
      this.pagination.limit = value
      this.getInstructionData()
    },
    downloadPending(selectedDate = 'months') {
      if (this.carrierCode !== '') {
        const params = {
          carrier_code: this.carrierCode
        }
        const queryParams = {
          carrier_code: this.carrierCode,
          status: this.selected,
          date_from_format: selectedDate
        }
        this.baseService.callService('exportShipmentsCarriers', queryParams, params)
          .then(response => {
            window.open(response.data[0], '_blank')
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            // this.loading.first = false
          })
      }
    },
    getAnswerCarrier() {
      this.carrierCode =  this.$session.get('cas_user').global_scope?.carrier_code[0] ?? ''
      if (this.carrierCode !== '') {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
          carrier_code: this.carrierCode
        }
        this.baseService.callService('getCarrierAnswer', {}, {})
          .then(response => {
            this.arrayAnswerCarrier = [...response.data]
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    getInstructionData() {
      this.loading.first = true
      if (this.carrierCode !== '') {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
          carrier_code: this.carrierCode
        }
        const queryParams = this.setQueryListInstructions()
        this.baseService.callService('getInstructions', queryParams, params)
          .then(response => {
            this.dataComponents.listInstructions = response.data || []
            if (response.data) this.pagination.total = response.pagination.totalCount
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            setTimeout(() => {
              this.loading.first = false
            }, 2000)
          })
      }
    },
    setQueryListInstructions() {
      const defaultMonth = new Date()
      defaultMonth.setMonth(defaultMonth.getMonth() - 1)
      return {
        page: this.pagination.page,
        limit: this.pagination.limit,
        status: this.selected,
        date_from: this.$options.filters.moment(defaultMonth, 'YYYY-MM-DD') 
      }
    }
  }
}
</script>