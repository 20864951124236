<template>
  <b-modal 
    id="idUploadAnswersRequest"
    :ok-disabled="!attachmentFile || attachmentFile.length === 0 || loading.updatingData"
    @ok="ok"
    title="Subir Instrucciones" modal-class="modal-warning"
    @show="show"
    ok-title="Subir archivos">
    <b-container>
      <b-row><span>{{ $t('Archivo de respuestas') }}</span></b-row>
      <b-row>
        <b-form-file
          :disabled="loading.updatingData"
          v-model="attachmentFile"
          :state="Boolean(attachmentFile)"
          multiple
          :file-name-formatter="formatNames"
          name="file"
          accept=".xls, .xlsx"
          class="cursor-pointer"
          :placeholder="$t('Elige un archivo...')" 
          :browse-text="$t('Buscar')" />
      </b-row>
      <b-row v-if="attachmentFile && attachmentFile.length > 0">
          <b-container>
            <b-row v-for="(file, index) in attachmentFile" :key="index">
              <b-col cols="6">
                <p class="pending--attachment--name">
                  {{ file.name }}
                </p>
              </b-col>
              <b-col cols="4">
                <b-progress 
                  v-if="fileLoading && fileLoading.length > 0 && fileLoading[index].loading && !fileLoading[index].error && !fileLoading[index].complete"
                  animated
                  :value="fileLoading && fileLoading.length > 0 && fileLoading[index] ? fileLoading[index].value : 0"
                  variant="warning"
                  :striped="striped"
                  class="mt-04">
                </b-progress>
                <b-progress
                  v-if="fileLoading && fileLoading.length > 0 && fileLoading[index].complete"
                  :value="100"
                  variant="success"
                  :striped="!striped"
                  class="mt-04">
                </b-progress>
                <b-progress
                  v-if="fileLoading && fileLoading.length > 0 && fileLoading[index].error"
                  animated
                  :value="100"
                  variant="danger"
                  :striped="striped"
                  class="mt-04">
                </b-progress>
              </b-col>
              <b-col cols="2">
                <b-button
                  @click="removeAttachment(index)"
                  v-if="fileLoading && fileLoading.length > 0 &&  (fileLoading[index].error  || !loading.updatingData ) && !fileLoading[index].complete"
                  variant="outline-danger"
                  class="p-0"
                  title="Eliminar adjunto">
                  <feather-icon icon="XIcon"/>
                </b-button>
                <feather-icon
                  v-else
                  icon="CheckIcon"
                  variant="light-success"/>
              </b-col>
              <b-col cols="12" class="mb-05">
                <p class="pending--attachment--name text-caption text-danger small"
                  v-if="fileLoading && fileLoading.length > 0 && fileLoading[index].error"> 
                  Error: {{fileLoading[index].errorText}}
                </p>
              </b-col>
            </b-row>
          </b-container>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  VBToggle,
  BProgress
} from 'bootstrap-vue'
import BaseServices from '@/store/services/index'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BProgress
  },
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      attachmentFile: null,
      loading: {
        updatingData: false
      },
      fileLoading: [],
      striped: true,
      baseService: new BaseServices(this),
      indexFile: 0
    }
  },
  methods: {
    show() {
      this.attachmentFile = []
      this.fileLoading = []
    },
    formatNames(files) {
      this.fileLoading = Array(files && files.length > 0 ? files.length : 0).fill(null).map(index => { return {value: 25, loading: false, complete: false, error: false, errorText: ''} })
      return files && files.length === 1 ? files[0].name : `${files.length} archivos seleccionados`
    },
    ok(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.loading.updatingData = true
      this.indexFile = 0
      this.uploadFile()
    },
    uploadFile() {
      if (this.attachmentFile && this.indexFile < this.attachmentFile.length && !this.fileLoading[this.indexFile].complete) {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
        }
        this.fileLoading[this.indexFile].loading = true
        this.baseService.callSpecialUploadFile('postFileInstructions', this.attachmentFile[this.indexFile], params, this.$session.get('cas_user'))
          .then(response => {
            this.fileLoading[this.indexFile].complete = true
            this.$emit('uploadSuccess', true)
          })
          .catch(err => {
            this.fileLoading[this.indexFile].error = true
            this.fileLoading[this.indexFile].errorText = err.message ?? 'No se pudo subir el archivo'
          }).finally(() => {
            this.fileLoading[this.indexFile].loading = false
            setTimeout(() => {
              this.indexFile++
              this.uploadFile()
            }, 1000)
          })
      } else if (this.attachmentFile && this.indexFile < this.attachmentFile.length && this.fileLoading[this.indexFile].complete) {
        setTimeout(() => {
          this.indexFile++
          this.uploadFile()
        }, 1000)
      } else if (this.indexFile === this.attachmentFile.length) {
        this.loading.updatingData = false
      }
    },
    removeAttachment(index) {
      this.fileLoading.splice(index, 1)
      this.attachmentFile.splice(index, 1)
    }
  }
}
</script>
<style  lang="scss">
  .pending--attachment--name {
    overflow-wrap: anywhere;
    margin: 0;
  }
</style>