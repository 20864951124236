<template>
  <b-modal
    id="idUpdateRequest"
    @ok="ok"
    title="Gestionar Instrucciones" modal-class="modal-warning"
    :ok-disabled="!validateForm || loading"
    ok-title="Ok"
    @close="closeModal"
    ok-only>
    <div v-if="detailInstruction">
      <p>
        {{ 'OT: ' + detailInstruction.delivery_tracking_number }}
      </p>
      <p>
        {{ 'Gestión: ' + detailInstruction.current_type_name }}
      </p>
      <b-form :validated="true">
        <b-form-checkbox v-model="form.resolved" switch required>
          Marcar como resuelto
        </b-form-checkbox>
        <b-form-select v-model="form.category_code" :options="optionsCategory" required></b-form-select>
        <b-form-textarea
          id="textarea"
          v-model="form.comment"
          placeholder="Ingrese Comentario"
          rows="3"
          max-rows="6"
          :required="commentRequired"
        ></b-form-textarea>
        <b-form-file v-model="form.file" :state="Boolean(form.file)" placeholder="Subir archivo opcional"  drop-placeholder="Suelta el archivo aquí..."></b-form-file>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['detailInstruction', 'arrayAnswerCarrier'],
  data() {
    return {
      baseService: new BaseServices(this),
      selected: false,
      form: {
        resolved: false,
        instruction_id: 0
      },
      commentRequired: true,
      loading: false
    }
  },
  computed: {
    optionsCategory() {
      return this.arrayAnswerCarrier.map(element => ({
        value: element.code,
        text: element.name
      }))
    },
    validateForm() {
      const validateCategory = !!this.form.category_code
      const validateTextArea = this.commentRequired ? !!this.form.comment : true
      return validateCategory && validateTextArea
    }
  },
  watch: {
    'form.category_code': {
      handler(value) {
        this.commentRequired = this.arrayAnswerCarrier.find(element => element.code === value).comment_required
      }
    }
  },
  methods: {
    ok(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.validateForm) {
        this.form.instruction_id = this.detailInstruction.id
        this.loading = true
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
          carrier_code: this.$session.get('cas_user').global_scope?.carrier_code[0] ?? ''
        }
        this.baseService.callSpecialUploadFile('updateInstructions', null, params, this.$session.get('cas_user'), this.form)
          .then(response => {
            this.$success('La instrucción ha sido actualizada correctamente')
            this.$emit('closeModal')
            this.form = {
              resolved: false,
              instruction_id: 0,
              comment: ''
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>